<template>
  <div class="animated fadeIn">
    <div id="snackbar"></div>
    <b-row>
      <b-col sm="12">
        <b-card>
          <b-row>
            <b-col sm="12" id="preview" class="mb-3"></b-col>
          </b-row>
          <div slot="header">
            <strong>{{ staticNames.name }}&nbsp;Critic Rating for Movie</strong>
            <small>Form</small>
          </div>
          <b-form v-on:submit.prevent.self="submitCriticByMovie">
            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="movie">
                    Select Movue
                    <span class="text-danger">*</span>
                  </label>
                  <v-select
                    :options="moviesList"
                    :clearable="false"
                    v-model="tempSelectedMovie"
                    :close-on-select="true"
                    :reduce="movie => movie.ID"
                    label="Title"
                    @input="onChangeMovieDropdwon($event)"
                    class="v-select-cust CustomVSelect Movies-V-select"
                    placeholder="Search Movie Name..."
                  >
                    <template slot="option" slot-scope="option">{{ option.Title }}</template>
                  </v-select>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="criticRating">
                    Critic Rating
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="number"
                    step="any"
                    v-model="criticRatingObj.CriticRating"
                    id="criticRating"
                    placeholder="Critic Rating 1~5"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="criticReview">
                    Critic Review
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="criticRatingObj.CriticReview"
                    id="criticReview"
                    placeholder="Critic Review"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="4">
                <b-form-group>
                  <label for="Title">
                    Title
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="criticRatingObj.Title"
                    id="Title"
                    placeholder="Title"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="4">
                <b-form-group>
                  <label for="Name">
                    Name
                    <span class="text-danger">*</span>
                  </label>
                  <b-form-input
                    type="text"
                    v-model="criticRatingObj.Name"
                    id="Name"
                    placeholder="Name"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col sm="4">
                <b-button type="submit" size="sm" variant="primary">
                  <i class="fa fa-dot-circle-o"></i>
                  {{ staticNames.name }}
                </b-button>
              </b-col>
              <b-col sm="4">
                <b-button @click="resetMovieBanner" size="sm" variant="success">
                  <i class="fa fa-dot-circle-o"></i> Reset
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-modal
      id="ModalConfirmationOnSubmit"
      ref="ModalConfirmationOnSubmit"
      @ok="handleModalOkay"
      :title="modalObject.title"
      class="center-footer"
      footer-class="justify-content-center border-0"
      cancel-variant="outline-danger"
      ok-variant="outline-success"
      centered
      hide-header
      no-close-on-esc
      no-close-on-backdrop
    >
      <div class="d-block text-center">
        <div>{{ modalObject.message }}</div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import MovieService from "@/services/MovieService";
import MasterService from "@/services/MasterService";
import { Switch as cSwitch } from "@coreui/vue";
import CinemaService from "@/services/CinemaService";

export default {
  data() {
    return {
      staticNames: {
        name: "Add"
      },
      moviesList: [],
      countryList: [],
      tempSelectedCountry: null,
      tempSelectedMovie: null,
      criticRatingObj: {
        MovieID: null,
        CriticRating: null,
        Title: "",
        CriticReview: "",
        Name: "",
        Flag: 1,
        ReviewID: ""
      }
    };
  },
  components: {
    cSwitch
  },
  computed: {},
  watch: {},
  beforeMount() {
    this.getMoviesList();

    const { MovieID, ReviewID } = this.$route.query;
    if (MovieID && ReviewID) {
      this.criticRatingObj.Flag = 2;
      this.criticRatingObj.MovieID = String(MovieID);
      this.staticNames.name = "Update";
      this.criticRatingObj.ReviewID = ReviewID;
    }
  },
  mounted: function() {},
  methods: {
    onChangeMovieDropdwon: function(event) {
      this.criticRatingObj.MovieID = event.ID;
    },
    getMoviesList: function() {
      this.moviesList = [];
      // MovieService.getMoviesDDL()
      MovieService.getActiveMovies()
        .then(response => {
          const { data } = response;
          if (data.Status && data.MovieList.length > 0) {
            this.moviesList = data.MovieList;
            if (this.criticRatingObj.MovieID && this.criticRatingObj.ReviewID) {
              this.getCriticsDetails(this.criticRatingObj.MovieID);
            }
            this.moviesList.map(x => {
              if (String(this.criticRatingObj.MovieID) == String(x.ID)) {
                this.tempSelectedMovie = x;
              }
            });
          } else {
            this.showMessageSnackbar(data.Message);
          }
        })
        .catch(error => {
          console.log("Catch on Banner-getMoviesDDL Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    getCriticsDetails: function() {
      let payload = {
        MovieID: this.criticRatingObj.MovieID
      };
      MovieService.getCriticsRatings(payload)
        .then(response => {
          const { data } = response;
          if (data.length > 0) {
            let temp = data.map(x => {
              if (x.ID == this.criticRatingObj.ReviewID) {
                return x;
              }
            });
            this.criticRatingObj.CriticRating = parseInt(temp[0].CriticRating);
            this.criticRatingObj.CriticReview = temp[0].CriticReview;
            this.criticRatingObj.Title = temp[0].Title;
            this.criticRatingObj.Name = temp[0].Name;
          } else {
            this.showMessageSnackbar(
              "Oops! Something went wrong. kindly contact your administrator"
            );
          }
        })
        .catch(err => {
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    resetMovieBanner: function() {
      this.criticRatingObj.MovieID = null;
      this.criticRatingObj.Flag = 1;
      this.criticRatingObj.ReviewID = null;
      this.criticRatingObj.CriticRating = null;
      this.criticRatingObj.CriticReview = null;
      this.criticRatingObj.Name = null;
      this.criticRatingObj.Title = null;
      window.location.replace(
        // window.location.origin + "/" + window.location.hash.split("?")[0]
        window.location.href.split("?")[0]
      );
    },
    submitCriticByMovie: function() {
      if (this.criticRatingObj.MovieID == null) {
        this.showSubmitConfirmation("Please Select Movie");
      } else {
        let payload = {
          title: "Confirmation",
          message: "Please re-check the data before submit"
        };
        this.showSubmitConfirmation(payload);
      }
    },
    submitFinalData: function() {
      this.closeModal("ModalConfirmationOnSubmit");
      let payload = {};
      console.log("this.criticRatingObj :", this.criticRatingObj);

      payload = {
        MovieID: this.criticRatingObj.MovieID,
        CriticRating: this.criticRatingObj.CriticRating,
        CriticReview: this.criticRatingObj.CriticReview,
        Flag: this.criticRatingObj.Flag,
        Title: this.criticRatingObj.Title,
        Name: this.criticRatingObj.Name
      };
      if (this.criticRatingObj.ReviewID) {
        payload["ReviewID"] = this.criticRatingObj.ReviewID;
      }
      console.log("payload :", payload);
      MovieService.addCriticRating(payload)
        .then(response => {
          const { data } = response;
          this.showMessageSnackbar(data.Message);
          if (data.Status) {
            this.criticRatingObj.MovieID = null;
            this.criticRatingObj.Flag = 1;
            this.criticRatingObj.CriticRating = null;
            this.criticRatingObj.CriticReview = null;
            this.criticRatingObj.Name = null;
            this.criticRatingObj.Title = null;
            this.$router.push({
              path: "/movies/list-critic-rating"
            });
          }
        })
        .catch(error => {
          console.log("Catch on Banner-addBanner Error: ", error);
          this.showMessageSnackbar(
            "Oops! Something went wrong. kindly contact your administrator"
          );
        });
    },
    // Show Confirmmation Dialog
    handleModalOkay: function(evt) {
      // // evt.preventDefault();
      this.modalObject.count = 1;
      this.submitFinalData();
    },
    showSubmitConfirmation: function(payload) {
      this.modalObject.title = payload.title; // "Confirmation";
      this.modalObject.message = payload.message; // "Please re-check the data before submit";
      this.openModal("ModalConfirmationOnSubmit");
    },
    openModal: function(ref) {
      this.$refs[ref].show();
    },
    closeModal: function(ref) {
      this.$refs[ref].hide();
      this.modalObject.title = ""; // "Confirmation";
      this.modalObject.message = ""; // "Please re-check the data before submit";
    },
    // Show Confirmmation Dialog End
    // Show Error Message
    showMessageSnackbar: function(message) {
      let x = document.getElementById("snackbar");
      x.className = "show";
      x.innerHTML = message;
      setTimeout(function() {
        x.className = x.className.replace("show", "");
      }, 3000);
    }
  },
  updated() {
    // .then(response => {
    //       const { data } = response;
    //       if (data.Status && data.Userrole.length > 0) {
    //       } else {
    //         this.showMessageSnackbar(data.Message);
    //       }
    //     })
    //     .catch(error => {
    //       console.log("Catch on AddUser-getRoles Error: ", error);
    //       this.showMessageSnackbar(
    //         "Oops! Something went wrong. kindly contact your administrator"
    //       );
    //     });
  }
};
</script>

<style>
</style>
